(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:RefereeList
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('partits', partits);

  function partits($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/partits/:dia/:mes/:any/:dia2/:mes2/:any2/:idperfil', 
    {dia: '@_dia',mes: '@_mes',any: '@_any',dia2: '@_dia2',mes2: '@_mes2',any2: '@_any2',idperfil: '@_idperfil'}, {
      update: {
        method: 'PUT'
      },
      query: {
        method: 'GET',
        isArray: true
      }
    });
  }
}());